<template>
  <footer class="h-footer-size px-10 bg-cafe fixed left-0 z-100 w-full">
    <div class="flex flex-col items-end">
      <img
        class="h-8"
        :src="require('@/assets/images/logo.png')"
        alt="t-recs logo"
      />
      <p class="text-blue-500 text-sm lg:text-base">
        SJ Sustainability Energy Suite Powered by T-RECs.ai. Copyright © 2021.
        All rights reserved.
      </p>
    </div>
  </footer>
</template>

<style lang="css" scoped></style>
