import jwtDecode from "jwt-decode";

export type DecodedToken = {
  readonly exp: number;
  readonly iat: number;
  readonly sub: number;
  readonly unique_name: number;
  readonly iss: string;

  readonly customerId: number;
  readonly userId: number;
  readonly email: string;
  readonly userName: string;
  readonly userRole: string;
  readonly phoneNumber: number | string;
  readonly avatarUrl: string;
};

export class AuthToken {
  readonly decodedToken: DecodedToken;

  constructor(readonly token?: string) {
    this.decodedToken = {} as DecodedToken;
    try {
      if (token) this.decodedToken = jwtDecode(token);
    } catch (err) {
      console.error("JWT decode get error", err);
    }
  }

  get expiresAt(): Date {
    return new Date(this.decodedToken.exp * 1000);
  }

  get isExpired(): boolean {
    return new Date() > this.expiresAt;
  }

  get isAuthenticated(): boolean {
    return !this.isExpired && !!this.token;
  }

  get authorizationString(): string {
    return `Bearer ${this.token}`;
  }
}

export interface ILoginForm {
  emailAddress: string;
  password: string;
}
