import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex justify-center items-center bg-black bg-opacity-30',
      ,
      $setup.fullScreen
        ? 'h-screen w-screen fixed top-0 left-0 z-100'
        : 'h-full w-full absolute top-0 left-0 z-100',
    ])
  }, [
    (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass(_ctx.styles['spinner']),
      viewBox: "0 0 50 50"
    }, [
      _createElementVNode("circle", {
        class: _normalizeClass(_ctx.styles['path']),
        cx: "25",
        cy: "25",
        r: "20",
        fill: "none",
        "stroke-width": "5"
      }, null, 2)
    ], 2))
  ], 2))
}